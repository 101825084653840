export const DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER = [
  {
    value: '#',
    text: 'Actions',
    sortable: false,
  },
  {
    value: 'id',
    text: 'N°',
    sortable: true,
    align: 'left',
  },
  {
    value: 'buildingNumber',
    text: 'N° Immeuble',
    sortable: true,
  },
  {
    value: 'iban',
    text: 'Iban',
    sortable: true,
  },
  {
    value: 'businessIdentifierCode',
    text: 'BIC',
    sortable: true,
  },
  {
    value: 'bankDomiciliation',
    text: 'Domiciliation',
    sortable: true,
  },
  {
    text: 'Nom du sdc',
    value: 'sdcName',
    sortable: true,
  },
  {
    value: 'rum',
    text: 'Rum',
    sortable: true,
  },
  {
    value: 'uploadedAt',
    text: 'Date d\'import',
    sortable: true,
  },
  {
    value: 'rumPath',
    text: 'Mandat signé',
    sortable: true,
  },
];

export const DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER_EDIT = [
  {
    value: 'buildingNumber',
    text: 'N° Immeuble',
    sortable: false,
  },
  {
    value: 'iban',
    text: 'Iban',
    sortable: false,
  },
  {
    value: 'businessIdentifierCode',
    text: 'BIC',
    sortable: false,
  },
  {
    value: 'bankDomiciliation',
    text: 'Domiciliation',
    sortable: false,
  },
  {
    text: 'Nom du sdc',
    sortable: false,
    value: 'sdcName',
  },
  {
    value: 'rum',
    text: 'Rum',
    sortable: false,
  },
];

export const DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER_DETAIL = [
  {
    value: 'id',
    text: 'N°',
    sortable: true,
    align: 'left',
  },
  {
    value: 'buildingNumber',
    text: 'N° Immeuble',
    sortable: false,
  },
  {
    value: 'iban',
    text: 'Iban',
    sortable: false,
  },
  {
    value: 'businessIdentifierCode',
    text: 'BIC',
    sortable: false,
  },
  {
    value: 'bankDomiciliation',
    text: 'Domiciliation',
    sortable: false,
  },
  {
    text: 'Nom du sdc',
    sortable: false,
    value: 'sdcName',
  },
  {
    value: 'rum',
    text: 'Rum',
    sortable: false,
  },
  {
    value: 'uploadedAt',
    text: 'Date d\'import',
    sortable: false,
  },
  {
    value: 'rumPath',
    text: 'Mandat signé',
    sortable: false,
  },
];

export default {
  buildEditHeader(activatedField = null) {
    if (!activatedField) {
      return this.defaultheader;
    }
    return this.defaultheader.filter((field) => activatedField.includes(field.value));
  },
  buildInitialHeader() {
    return this.defaultheader.filter((field) => {
      if (field.checked !== false) {
        return field;
      }
      return null;
    });
  },
  defaultheader: DEFAULT_CUSTOMER_SDC_BANKING_ACCOUNTS_HEADER,
};
